import React from "react";

// interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";

function GoSearchIcon(props: IconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        stroke="currentColor"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.49658 16.002V18.0028C3.49658 19.6603 4.84029 21.004 6.49783 21.004H8.49867"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.502 2.99609H18.5028C20.1603 2.99609 21.504 4.3398 21.504 5.99734V7.99818"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.502 21.004H18.5028C20.1603 21.004 21.504 19.6603 21.504 18.0028V16.002"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.49658 7.99818V5.99734C3.49658 4.3398 4.84029 2.99609 6.49783 2.99609H8.49867"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4686 16.9691L15.1831 14.6836L17.4686 16.9691Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9999 6.99805C14.4862 6.99805 16.5018 9.0136 16.5018 11.4999C16.5018 13.9862 14.4862 16.0018 11.9999 16.0018C9.5136 16.0018 7.49805 13.9862 7.49805 11.4999V11.4999C7.49805 9.0136 9.5136 6.99805 11.9999 6.99805"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

export default GoSearchIcon;
