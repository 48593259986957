import React from "react";

//interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";


const AllCampaignsIcon = (props: IconComponentProps<HTMLSpanElement>) => {
  return (
    <span { ...props }>
      <svg width="25" height="24" viewBox="0 0 25 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49835 3.99693L9.79889 2.37125C9.98883 2.13401 10.2763 1.99598 10.5802 1.99609H14.4208C14.7247 1.99598 15.0122 2.13401 15.2021 2.37125L16.5017 3.99693V3.99693V4.99734C16.5017 5.54986 16.0538 5.99776 15.5013 5.99776H9.49877C8.94625 5.99776 8.49835 5.54986 8.49835 4.99734V3.99693Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.166 9.33203L8.49834 10.9997L7.49792 9.99931" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5004 9.99923H16.5017" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.4996 20.0037H6.49748C5.39245 20.0037 4.49664 19.1079 4.49664 18.0029V5.9979C4.49664 4.89287 5.39245 3.99707 6.49748 3.99707H8.49831" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.7466 20.4884C18.4166 20.8185 17.9688 21.0038 17.5021 21.0036V21.0036C17.0353 21.0038 16.5876 20.8185 16.2576 20.4884L14.1487 18.3795C13.3142 17.5101 13.3283 16.133 14.1804 15.2809C15.0325 14.4288 16.4096 14.4147 17.279 15.2492L17.5021 15.4723L17.7252 15.2492C18.2804 14.6708 19.1049 14.4378 19.8807 14.64C20.6566 14.8422 21.2625 15.4481 21.4647 16.2239C21.6669 16.9998 21.4339 17.8243 20.8555 18.3795L18.7466 20.4884Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5016 3.99707H18.5025C19.6075 3.99707 20.5033 4.89287 20.5033 5.9979V12.0004" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.49792 15.0012H9.49876" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
};


export default AllCampaignsIcon;

