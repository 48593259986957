import React from "react";

// interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";

function SupportIcon(props: IconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.04995 12.95L4.69995 15.3M12.9416 7.05L15.3 4.7M4.69995 4.7L7.04995 7.05M12.95 12.95L15.3 15.3M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3743 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967ZM12.9462 7.05372C14.5734 8.68091 14.5734 11.3191 12.9462 12.9463C11.319 14.5735 8.68083 14.5735 7.05364 12.9463C5.42646 11.3191 5.42646 8.68091 7.05364 7.05372C8.68083 5.42654 11.319 5.42654 12.9462 7.05372Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

export default SupportIcon;
