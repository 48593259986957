import { StylesConfig, CSSObjectWithLabel, ContainerProps, GroupBase } from "react-select";

// interface
import { HeaderSelectInterface } from "./HeaderSelect.interface";


const generateControlStyles = (
  stylesProps: CSSObjectWithLabel,
  state: ContainerProps<unknown, false, GroupBase<unknown>>,
  type: HeaderSelectInterface["type"],
  controlBorder: string
) => ({
  ...stylesProps,
  backgroundColor: "transparent",
  border: state.isFocused ? "1px solid var(--primary-color-400)" : controlBorder,
  boxShadow: state.isFocused ? "0px 0px 0px 1px rgba(229, 231, 235)" : "none",
  filter: type === "secondary" ? "drop-shadow(0px 1px 2px rgba(31, 41, 55, 0.08))" : "none",
  borderRadius: "4px",
  outline: "none",
  width: "100%",
  color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
  cursor: "text",
  pointerEvents: "all",
  opacity: "1",
  padding: "0",
  "&:hover": {
    border: "1px solid var(--primary-color-400)",
    boxShadow: "0px 0px 0px 1px rgba(229, 231, 235)"
  },
});

const generateMenuStyles = (
  stylesProps: CSSObjectWithLabel,
  type: HeaderSelectInterface["type"],
  fullWidth: boolean
) => ({
  ...stylesProps,
  zIndex: "9999",
  color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
  background: "#FFFFFF",
  marginTop: "12px",
  width: fullWidth ? "100%" : "max-content",
  minWidth: "100%",
  borderRadius: "8px",
  maxWidth: fullWidth ? "100%" : "270px",
  border: "1px solid #D1D5DB",
  boxShadow: "0px 20px 25px 0px rgba(31, 41, 55, 0.10), 0px 10px 10px 0px rgba(31, 41, 55, 0.04)",
  overflow: "hidden",
});

const generateOptionsStyles = (
  stylesProps: CSSObjectWithLabel,
  { isFocused, isDisabled }: ContainerProps<unknown, false, GroupBase<unknown>>
) => ({
  ...stylesProps,
  backgroundColor: isFocused ? "rgba(19, 181, 172, 0.1);" : "transparent",
  color: isFocused ? "#13b5ac" : "#374151",
  opacity: isDisabled ? ".5" : "1",
  cursor: isDisabled ? "not-allowed" : "pointer",
  fontSize: "14px",
  display: "flex",
  fontFamily: "'Inter', sans-serif",
  fontWeight: "500",
  columnGap: "8px",
  svg: { fontSize: "18px" }
});

export const generateHeaderSelectStyles = (
  { fullWidth, type }: HeaderSelectInterface
): StylesConfig<unknown, false> => {
  const controlBorder = type === "secondary" ? "1px solid var(--secondary-color-300)" : "1px solid transparent";

  return {
    container: stylesProps => ({
      ...stylesProps,
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      borderRadius: "4px",
      color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
      padding: "1px",
    }),
    control: (stylesProps, state) => generateControlStyles(
      stylesProps, state, type, controlBorder
    ) as CSSObjectWithLabel,
    input: (stylesProps) => ({
      ...stylesProps,
      color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
      fontSize: "14px",
      paddingLeft: "16px"
    }),
    valueContainer: (stylesProps, state) => ({
      ...stylesProps,
      height: state.isMulti ? "auto" : "32px",
      top: "0",
      padding: "0 10px",
      color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
      fontSize: "14px",
      marginLeft: "10px"
    }),
    singleValue: stylesProps => ({
      ...stylesProps,
      color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
      fontSize: "14px",
      marginLeft: "16px"
    }),
    menu: stylesProps => generateMenuStyles(stylesProps, type, fullWidth),
    menuList: stylesProps => ({
      ...stylesProps,
      padding: "0",
    }),
    option: generateOptionsStyles,
    indicatorsContainer: stylesProps => ({
      ...stylesProps,
      height: "32px",
    }),
    placeholder: stylesProps => ({
      ...stylesProps,
      color: type === "secondary" ? "var(--secondary-color-500)" : "#FFFFFF",
      opacity: 0.6,
      fontFamily: "'Inter', sans-serif",
      whiteSpace: "nowrap",
      marginLeft: "16px"
    })
  };
};
