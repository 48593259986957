// helpers
import { useFetch, usePost, usePut } from "./reactQuery";
import API_ROUTES from "../routes.constant";
import { pathToUrl } from "./reactQuery/pathToUrl";

// interfaces
import { QueryArgsInterface, IUseMutation } from "@/types/api/queryArgs.interface";
import { IPaginatedResponse } from "@/types/api/PaginatedResponse";
import { IConversationMessageSimple, IConversationRoom, IConversationRoomLLSimple, IConversationRoomSimple } from "@/types/api/Conversation.interface";
import { IChatMessage } from "@/types/api/ChatMessage.interface";


type QueryByIdArgs = { roomId: number } & QueryArgsInterface;
type TUseMutateById<TData = unknown, TVariables = unknown> = {
  roomId: string | number;
} & Omit<IUseMutation<TData, TVariables>, "slug">;

const conversation = {
  useConversationMessages: ({ roomId, params, config }: QueryByIdArgs) => {

    const fetchKey = roomId ? pathToUrl(API_ROUTES.conversationsUrls.conversationsMessages, { id: roomId }) : null;

    return useFetch<IPaginatedResponse<IChatMessage>>(fetchKey, params, config);
  },

  useConversationMessagesPage: (args: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<IConversationRoomSimple | IConversationRoomLLSimple>>(
      API_ROUTES.conversationsUrls.conversationMessagesPage,
      params,
      config
    );
  },

  usePostConversationMessage: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.conversationsUrls.conversationMessages, params, config);
  },

  useGetConversationMessages: (args: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<IConversationMessageSimple>>(
      API_ROUTES.conversationsUrls.conversationMessages,
      params,
      config
    );
  },

  usePostConversationRoom: (args?: IUseMutation<{ id: number }>) => {
    const { params, config } = args || {};

    return usePost<{ id: number }>(API_ROUTES.conversationsUrls.conversationsRooms, params, config);
  },

  useConversationRooms: (args: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<IConversationRoom>>(
      API_ROUTES.conversationsUrls.conversationsRooms,
      params,
      config
    );
  },

  useConversationsRoomSync: (args: TUseMutateById<{ lastSync: string }>) => {
    const { roomId, params, config } = args || {};
    const fetchKey = roomId ? pathToUrl(API_ROUTES.conversationsUrls.conversationsRoomSync, { id: roomId }) : null;

    return usePut<{ lastSync: string }>(fetchKey, params, { ...(config || {}), enabled: !!roomId } as TUseMutateById<{ lastSync: string }>["config"]);
  },

  useConversationRoom: (args: QueryByIdArgs) => {
    const { roomId, params, config } = args || {};
    const fetchKey = roomId ? pathToUrl(API_ROUTES.conversationsUrls.conversationsSingleRoom, { id: roomId }) : null;

    return useFetch<IConversationRoom>(fetchKey, params, { ...(config || {}), enabled: !!roomId });
  },

  usePostShowHirerAnytimeMessage: (args: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(API_ROUTES.conversationsUrls.conversationsProactiveMessageAnalytics, params, config);
  },
};

export default conversation;
