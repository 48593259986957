import { useFetch } from "./reactQuery";
import API_ROUTES from "@/api/routes.constant";

// helpers
import { REGIONS } from "@/helpers/regions.constant";

// interfaces
import { AbstractRespListItemInterface } from "@/types/api/AbstractRespListItem.interface";


interface IGeographicRegion extends AbstractRespListItemInterface {
  code: string;
}

interface IPlatform extends AbstractRespListItemInterface {
  label: string;
}

export default {
  useGeographicRegion: () => {
    return useFetch<IGeographicRegion[]>(API_ROUTES.geographicRegions, {}, {
      placeholderData: () => REGIONS.map((region) => ({ name: region.label, code: region.value.replace("/", ""), id: region.code })),
    });
  },
  usePlatforms: () => {
    return useFetch<IPlatform[]>(API_ROUTES.platforms);
  },
}
