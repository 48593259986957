import React from "react";
import { Popover } from "antd";

// components
import Button from "@/shared/Button";
import LoginForm from "@/components/Auth/LoginForm";

// interfaces
import { IHeaderAuthPopover } from "./HeaderAuthPopover.interface";

// assets
import styles from "@/assets/styles/components/header-auth-popover.module.scss";


const HeaderAuthPopover = ({ onSubmit }: IHeaderAuthPopover) => (
  <Popover
    placement="bottomRight"
    trigger="click"
    overlayClassName={styles["locl-header-auth-popover"]}
    getPopupContainer={(el) => el}
    content={<LoginForm isWithFooter={process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL"} onSubmit={onSubmit} />}
  >
    <Button data-testid="locl-hirer-header-unauthorized-login-button">
      Log in
    </Button>
  </Popover>
);

export default HeaderAuthPopover;
