import React, { ReactNode } from "react";
import { Dropdown } from "antd";
import { FaChevronDown } from "react-icons/fa";

// components
import Link from "@/components/Link";

// helpers
import { hirerLandingMenuItems, hirerMenuItems, landlordMenuItems } from "./helpers";

// interfaces
import { IHeaderProfileDropdown } from "./HeaderProfileDropdown.interface";

// assets
import styles from "@/assets/styles/components/header-profile-dropdown.module.scss"

interface IMenuItemLink {
  link?: string;
  key: string;
  label: string | ReactNode;
  className?: string;
  type?: string;
}

const HeaderProfileDropdown = ({ user, onLogout, unreadMessages, isLanding }: IHeaderProfileDropdown) => {
  const { firstName = "", type } = user || {};

  // renders
  const renderMenuItemsLink = (data: IMenuItemLink) => {
    switch (true) {
      case data.key === "logout":
        return (
          <div
            onClick={data.key === "logout" ? onLogout : () => null}
            key={data.key}
          >
            {data.label}
          </div>
        )
      case data.key === "locations":
        return (
          <Link
            href="/locations"
            key={data.key}
          >
            {data.label}
          </Link>
        )
      default:
        return (
          <Link
            href={data.link || "#"}
            key={data.key}
          >
            {data.label}
          </Link>
        )
    }
  }

  const hirerMenuList = isLanding ? hirerLandingMenuItems(unreadMessages as number) : hirerMenuItems;

  const menuItems = (type === "landlord" ? landlordMenuItems : hirerMenuList).map(el => (
    {
      ...el,
      label: renderMenuItemsLink(el as IMenuItemLink)
    }
  ));


  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      overlayClassName={styles["locl-header-profile-dropdown_dropdown"]}
      menu={{ items: menuItems as IMenuItemLink[] }}
      trigger={["click"]}
      placement="bottom"
    >
      <div className={styles["locl-header-profile-dropdown"]} data-testid="locl-header-profile-dropdown">
        <div className={styles["locl-header-profile-dropdown_text-hello"]} data-testid="locl-header-profile-dropdown-hello">Hello,</div>
        <div className={styles["locl-header-profile-dropdown_info"]}>
          <div className={styles["locl-header-profile-dropdown_text-name"]} data-testid="locl-header-profile-dropdown-name">{firstName}</div>
          <div className={styles["locl-header-profile-dropdown_arrow"]}><FaChevronDown className={styles["locl-header-profile-dropdown_arrow_icon"]}/></div>
        </div>
      </div>
    </Dropdown>
  );
};

export default HeaderProfileDropdown;
