// helpers
import { useDelete, useFetch, usePost, usePatch, usePut } from "./reactQuery";
import { pathToUrl } from "./reactQuery/pathToUrl";
import API_ROUTES from "@/api/routes.constant";

// interfaces
import { SingleLocationResponseInterface } from "@/types/api/SingleLocationResponse.interface";
import { QueryArgsInterface, IUseMutation } from "@/types/api/queryArgs.interface";
import { IUseQueryResult } from "@/types/api/IUseQueryResult.interface";
import { QuickSearchResult } from "@/types/api/QuickSearchResult.interface";
import { IPaginatedResponse } from "@/types/api/PaginatedResponse";
import { AbstractRespListItemInterface } from "@/types/api/AbstractRespListItem.interface";
import { ILocationsResponse, IShortLocation, ILocationCalendarResponse, ILocationMemberResponse, ILocationConversion } from "@/types/api/Locations.interface";

const { locationsUrls, previewUrls } = API_ROUTES;

type QueryBySlugArgs = {
  slug?: string | null;
  preview?: string;
} & QueryArgsInterface;

const locations = {
  useLocationsSimplifiedList: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<IShortLocation>>(
      locationsUrls.locationsShort,
      params,
      config
    )
  },

  useLocation: ({ slug, preview, params, config = {} }: QueryBySlugArgs) => {
    const fetchKey = slug ? pathToUrl(locationsUrls.locationsSingle, { slug }) : null;
    const fetchKeyPreview = slug ? pathToUrl(previewUrls.previewLocation, { slug }) : null;

    return useFetch(
      preview ? fetchKeyPreview : fetchKey,
      params,
      { ...config, axiosConfig: { headers: { "LOCATION-AUTH": `Bearer ${preview}` } } }
    ) as IUseQueryResult<SingleLocationResponseInterface>;
  },

  usePostLocation: () => usePost<SingleLocationResponseInterface>(locationsUrls.locations),

  usePatchLocation: (slug: string) => usePatch<SingleLocationResponseInterface>(
    slug ? pathToUrl(locationsUrls.locationsSingle, { slug }) : null
  ),

  useRefreshAvailability: (slug: string) => {
    const fetchKey = slug ? pathToUrl(locationsUrls.updateAvailability, { slug }) : null;

    return useFetch(fetchKey, {}, { enabled: false });
  },

  useQuickSearch: (args?: QueryArgsInterface | null) => {
    const { params } = args || {};

    return useFetch<IPaginatedResponse<QuickSearchResult>>(
      params ? locationsUrls.quickSearch : null,
      params
    );
  },

  useLocationCalendar: (args?: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationCalendar, { slug }) : null;

    return useFetch<ILocationCalendarResponse[]>(fetchKey, params, config);
  },

  useBulkStatusUpdate: (args?: IUseMutation) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationCalendarBulkStatusUpdate, { slug }) : null;

    return usePatch(fetchKey, params, config);
  },

  useGetLocations: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<ILocationsResponse>>(locationsUrls.locations, params, config);
  },

  usePublishLocation: (args: IUseMutation) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationPublish, { slug }) : null;

    return usePut(fetchKey as string, params, config);
  },

  useRestoreLocation: (args: IUseMutation) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationRestore, { slug }) : null;

    return usePut(fetchKey as string, params, config);
  },

  useArchiveLocation: (args: IUseMutation) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationArchive, { slug }) : null;

    return usePut(fetchKey as string, params, config);
  },
  
  useDeleteLocation: (args: IUseMutation) => {
    const { params } = args;

    return useDelete(locationsUrls.locations, params);
  },

  useLocationMembers: (args: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationMembers, { slug }) : null;

    return useFetch<ILocationMemberResponse>(fetchKey, params, config);
  },

  useLocationConversion: (args: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationConversion, { slug }) : null;

    return useFetch<IPaginatedResponse<ILocationConversion>>(fetchKey, params, config);
  },

  usePostLocationPartnerEnquiry: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(locationsUrls.locationPartnerEnquiry, params, config);
  },

  useLocationCampaignItems: (args?: QueryBySlugArgs & { id: number | string }) => {
    const { slug, id, params, config } = args || {};
    const fetchKey = slug && id ? pathToUrl(locationsUrls.locationCampaignItems, { slug, id }) : null;

    return useFetch<AbstractRespListItemInterface[]>(fetchKey, params, config);
  },

  usePostLocationNudge: ({ slug, params, config }: IUseMutation) => {
    const fetchKey = slug ? pathToUrl(locationsUrls.locationNudge, { slug }) : null;

    return usePost(fetchKey as string, params, config);
  },

  useUpdateLocation: (args: IUseMutation) => {
    const { slug, params } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationsSingle, { slug }) : null;

    return usePatch<SingleLocationResponseInterface>(fetchKey, params);
  },
};

export default locations;
