import groupBy from "lodash/groupBy";

// interfaces
import { QuickSearchResult } from "@/types/api/QuickSearchResult.interface";

const messageOption = (inputValue: string) => ({
  label: inputValue,
  name: "Use this look up to go to a region, location or space featured on lo:live",
  type: "message",
  isDisabled: true,
})

export const handleHeaderSelectOption = (
  value: QuickSearchResult[],
  lang: string,
  inputValue: string,
  isShowHint: boolean
) => {

  if (!value.length) {
    return [isShowHint && messageOption(inputValue)].filter(Boolean);
  }

  const groupedList = groupBy(value, "region")

  if (lang === "uk") {
    return [
      isShowHint && messageOption(inputValue),
      ...(groupedList.undefined || []).map(item => ({ ...item, label: item.name })),
      {
        label: "UK",
        options: (groupedList.UK || []).map(item => ({ ...item, label: item.name }))
      },
      {
        label: "Europe",
        options: (groupedList.Europe || []).map(item => ({ ...item, label: item.name }))
      },
      {
        label: "Australia",
        options: (groupedList.Australia || []).map(item => ({ ...item, label: item.name }))
      }
    ].filter(Boolean);
  }

  return [
    isShowHint && messageOption(inputValue),
    ...(groupedList.undefined || []).map(item => ({ ...item, label: item.name })),
    {
      label: "Australia",
      options: (groupedList.Australia || []).map(item => ({ ...item, label: item.name }))
    },
    {
      label: "UK",
      options: (groupedList.UK || []).map(item => ({ ...item, label: item.name }))
    },
    {
      label: "Europe",
      options: (groupedList.Europe || []).map(item => ({ ...item, label: item.name }))
    }
  ].filter(Boolean);
};
