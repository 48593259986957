import React from "react";

// component
import Tag from "@/shared/Tag";

// interfaces
import { AuthFormWrapperInterface } from "./AuthFormWrapper.interface";

// assets
import styles from "@/assets/styles/components/Auth/auth-form-wrapper.module.scss";


const AuthFormWrapper = ({ title, subtitle, message, children, className = "" }: AuthFormWrapperInterface) => {


  return (
    <div className={`${styles["locl-auth-form-wrapper"]} ${className}`} data-testid="locl-auth-form-wrapper">
      {
        (title || subtitle) && (
          <div className={styles["locl-auth-form-wrapper__header"]}>
            {title && (
              <h1
                className={styles["locl-auth-form-wrapper__title"]}
                data-testid="locl-auth-form-wrapper-title"
              >
                {title}
              </h1>
            )}
            {subtitle && (
              <div
                className={styles["locl-auth-form-wrapper__subtitle"]}
                data-testid="locl-auth-form-wrapper-subtitle"
              >
                {subtitle}
              </div>
            )}
          </div>
        )
      }
      {message && (
        <Tag
          color="green"
          className={styles["locl-auth-form-wrapper__message"]}
          closable
        >
          {message}
        </Tag>
      )}
      <div className={styles["locl-auth-form-wrapper__body"]}>
        {children}
      </div>
    </div>

  );
};

export default AuthFormWrapper;
