import React from "react";

// interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";

function LegalIcon(props: IconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66665 10.8333H13.3333M6.66665 14.1667H13.3333M16.6666 6.66667H13.3333C12.8733 6.66667 12.5 6.29333 12.5 5.83333V2.5M6.66665 7.5H9.16665M4.99998 2.5H12.6433C13.085 2.5 13.5091 2.67583 13.8216 2.98833L16.1783 5.345C16.4908 5.6575 16.6666 6.08167 16.6666 6.52333V15.8333C16.6666 16.7542 15.9208 17.5 15 17.5H4.99998C4.07915 17.5 3.33331 16.7542 3.33331 15.8333V4.16667C3.33331 3.24583 4.07915 2.5 4.99998 2.5Z"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

export default LegalIcon;
