import Input from "./Input";
import InputNumber from "./InputNumber";
import InputSkeleton from "./InputSkeleton";
import Password from "./Password";
import TextArea from "./TextArea";

import {
  InputProps,
  InputNumberProps,
  InputSkeletonProps,
  PasswordProps,
  TextAreaProps
} from "./Input.interface";

const CustomInput = Input as React.FC<InputProps> & {
  Password: typeof Password;
  TextArea: typeof TextArea;
};

CustomInput.TextArea = TextArea;
CustomInput.Password = Password;

export type { InputProps, InputNumberProps, InputSkeletonProps, PasswordProps, TextAreaProps };
export { InputNumber, InputSkeleton };
export default CustomInput;
