/* eslint-disable no-undefined */
import React, { forwardRef, Ref } from "react";
import dynamic from "next/dynamic";
import { components, ValueContainerProps, OptionProps, StylesConfig } from "react-select";
import { BiCube } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Tooltip } from "antd";

const ReactSelect = dynamic(() => import("react-select"), { ssr: false });

// interface
import { HeaderSelectInterface } from "./HeaderSelect.interface";

// helpers
import { generateHeaderSelectStyles } from "./helpers";

//assets
import CityIcon from "../../icons/CityIcon";

import styles from "@/assets/styles/components/hirer-header-search.module.scss"
import classNames from "classnames";


const SEARCH_SUGGESTION_ICONS = {
  "search_filter": <HiOutlineLocationMarker data-testid="locl-header-search-filter-icon"/>,
  "location": <CityIcon data-testid="locl-header-location-icon"/>,
  "space": <BiCube data-testid="locl-header-space-icon"/>,
};


const ValueContainer = ({ children, ...props }: ValueContainerProps) => (
  components.ValueContainer && (
    <components.ValueContainer {...props}>
      {!!children && (
        <AiOutlineSearch
          className="fa fa-search"
          aria-hidden="true"
          style={{ position: "absolute", fontSize: "20px" }}
        />
      )}
      {children}
    </components.ValueContainer>
  )
);

interface OptionInterface extends Omit<OptionProps<unknown>, "data"> {
  data: unknown;
}

const Option = ({ data, ...props }: OptionInterface) => {
  const suggestion = data as { name: string; type: "location" | "space" | "search_filter" | "message" };

  if (suggestion.type === "message") {
    return (
      <components.Option {...props}
        className={classNames(
          styles["locl-header-select-option"],
          styles["locl-header-select-option__message"],
          props.className
        )}
        data={undefined}
      >
        <span className={styles["locl-header-select-option__message__text"]}>{suggestion.name}</span>
      </components.Option>
    )
  }

  return (
    <components.Option {...props} className={`${styles["locl-header-select-option"]} ${props.className}`} data={undefined}>
      <Tooltip
        placement="right"
        title={suggestion.name}
        overlayClassName={styles["locl-header-select-option__tooltip"]}
        className={styles["locl-header-select-option__body"]}
      >
        <span className={styles["locl-header-select-option__icon"]}>{SEARCH_SUGGESTION_ICONS[suggestion.type]}</span>
        <span className={styles["locl-header-select-option__text"]}>{suggestion.name}</span>
      </Tooltip>
    </components.Option>
  )
};


const HeaderSelect = forwardRef(({ fullWidth, type = "primary", ...props }: HeaderSelectInterface, ref: Ref<unknown>) => {
  const selectStyles = generateHeaderSelectStyles({ fullWidth, type });

  return (
    <ReactSelect
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      styles={selectStyles as unknown as StylesConfig<unknown, boolean>}
      noOptionsMessage={() => null}
      className={"locl-header-search"}
      classNamePrefix={"locl-header-search"}
      aria-label="locl-header-search-select"
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        ValueContainer,
        Option: Option,
        MenuList: ({ children, ...restMenuProps }) => (
          <components.MenuList {...restMenuProps}>
            <div data-testid="locl-header-search-select-list">{children}</div>
          </components.MenuList>
        ),
        Group: ({ children, ...restProps }) => (
          <components.Group {...restProps} className={`${styles["locl-header-select-group"]} ${restProps.className}`}>
            {children}
          </components.Group>
        ),
        GroupHeading: ({ children, ...restProps }) => (
          <components.GroupHeading {...restProps} className={`${styles["locl-header-select-group-label"]} ${restProps.className}`}>
            <span>{children}</span>
          </components.GroupHeading>
        )
      }}
      { ...props }
    />
  );
});

HeaderSelect.displayName = "HeaderSelect";

export default HeaderSelect;
