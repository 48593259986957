import classNames from "classnames";

// components
import Button from "@/shared/Button";

// assets
import LogoutIcon from "@/components/icons/LogoutIcon";
import AllCampaignsIcon from "@/components/icons/AllCampaignsIcon";
import BookingsIcon from "@/components/icons/BookingsIcon";
import MyMessagesIcon from "@/components/icons/MyMessagesIcon";
import UserProfile from "@/components/icons/UserProfile";
import SettingsIcon from "@/components/icons/SettingsIcon";
import LegalIcon from "@/components/icons/LegalIcon";
import BookDemoIcon from "@/components/icons/BookDemoIcon";
import SupportIcon from "../icons/SupportIcon";

import styles from "@/assets/styles/components/header-profile-dropdown.module.scss"

export const hirerLandingMenuItems = (unreadMessages: number | null) => [
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <UserProfile className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Account
      </span>
    ),
    link: "/profile",
    key: "profile",
  },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <SettingsIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Settings
      </span>
    ),
    link: "/settings/organisation-members",
    key: "settings",
  },
  { type: "divider", },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <AllCampaignsIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Campaigns
      </span>
    ),
    link: "/campaigns",
    key: "campaigns",
  },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <BookingsIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Bookings
      </span>
    ),
    link: "/bookings",
    key: "Bookings",
  },
  {
    label: <div className={styles["locl-header-profile-dropdown_dropdown__item"]}>
      <MyMessagesIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Messages
      { !!unreadMessages && <div className={styles["locl-header-profile-dropdown_dropdown__item__badge"]}>{ +(unreadMessages as number) > 99 ? "+99" : unreadMessages }</div> }
    </div>,
    link: "/my-messages",
    key: "messages",
  },
  { type: "divider", },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <LegalIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Legal
      </span>
    ),
    link: "/terms-of-use",
    key: "terms-of-use",
  },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <SupportIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Support
      </span>
    ),
    link: "/support",
    key: "support",
  },
  {
    label: (
      <Button to="/book-a-demo" fullWidth className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <BookDemoIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Book a demo
      </Button>
    ),
    link: "/book-a-demo",
    key: "book-a-demo",
    className: styles["locl-header-profile-dropdown_dropdown__button"]
  },
  {
    key: "logout",
    label: (
      <span data-testid="locl-header-profile-dropdown-logout" className={classNames(styles["locl-header-profile-dropdown_dropdown__item"], styles["locl-header-profile-dropdown_dropdown__item__logout"])}>
        <LogoutIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Log Out
      </span>
    ),
  },
];

export const hirerMenuItems = [
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <UserProfile className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Account
      </span>
    ),
    link: "/profile",
    key: "profile",
  },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <SettingsIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Settings
      </span>
    ),
    link: "/settings/organisation-members",
    key: "settings",
  },
  { type: "divider", },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <LegalIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Legal
      </span>
    ),
    link: "/terms-of-use",
    key: "terms-of-use",
  },
  {
    label: (
      <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>
        <SupportIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Support
      </span>
    ),
    link: "/support",
    key: "support",
  },
  {
    label: (
      <Button to="/book-a-demo" fullWidth className={classNames(styles["locl-header-profile-dropdown_dropdown__item"], styles["locl-header-profile-dropdown_dropdown__item__book-demo"])}>
        <BookDemoIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Book a demo
      </Button>
    ),
    link: "/book-a-demo",
    key: "book-a-demo",
    className: styles["locl-header-profile-dropdown_dropdown__button"]
  },
  {
    key: "logout",
    label: (
      <span data-testid="locl-header-profile-dropdown-logout" className={classNames(styles["locl-header-profile-dropdown_dropdown__item"], styles["locl-header-profile-dropdown_dropdown__item__logout"])}>
        <LogoutIcon className={styles["locl-header-profile-dropdown_dropdown__item__icon"]} /> Log Out
      </span>
    ),
  },
];

export const landlordMenuItems = [
  {
    link: "/locations",
    label: <span className={styles["locl-header-profile-dropdown_dropdown__item"]}>My locations</span>,
    key: "locations"
  },
  {
    key: "logout",
    label: <span data-testid="locl-header-profile-dropdown-logout" className="locl-header-profile-dropdown_dropdown__item logout">Log Out</span>,
  },
];
