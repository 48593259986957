import React, { useCallback, useState } from "react";
import { useParams } from "next/navigation";
import debounce from "lodash/debounce";

//components
import HeaderSelect from "./HeaderSelect";

//helpers
import { handleHeaderSelectOption } from "./helpers";
import locations from "@/api/query/locations";
import useRouter from "@/hooks/useRouter";

// interfaces
import { HirerHeaderSearchInterface } from "./HirerHeaderSearch.interface";
import { QuickSearchResult } from "@/types/api/QuickSearchResult.interface";

// assets
import styles from "@/assets/styles/components/hirer-header-search.module.scss"
import { useDimension } from "@/hooks/useDimensions";

const SEARCH_VALUE_TYPES = {
  space: "space",
  filter: "search_filter"
};

const HirerHeaderSearch = (props: HirerHeaderSearchInterface) => {
  const [query, setQuery] = useState("");
  const [selectInputValue, setSelectInputValue] = useState("");
  const [isShowSelectMenu, setIsShowSelectMenu] = useState(false);

  const router = useRouter();
  const { lang } = useParams();
  const [width] = useDimension();

  const { data } = locations.useQuickSearch(query ? { params: { query, limit: 150 } } : null);

  const isDesktop = width > 1200;

  const handleInputChange = useCallback(debounce(value => {
    if (value.length < 3) {
      setQuery("")
    } else {
      setQuery(value)
    }
  }, 350), []);

  const handleSelect = (value: QuickSearchResult) => {
    switch (true) {
      case value.type === SEARCH_VALUE_TYPES.space:
        router.push(`/location/${value.locationSlug}/space/${value.slug}`);

        break;
      case value.type === SEARCH_VALUE_TYPES.filter:
        router.push(`/${value.url}`);

        break;
      default:
        router.push(`/${value.type}/${value.slug}`);

        break;
    }

    if (props.onAfterSelected) {
      props.onAfterSelected();
    }
  };

  const handleSelectMenuOpen = () => {
    setIsShowSelectMenu(true);
  };

  return (
    <div
      className={styles["locl-hirer-header-search"]}
      data-testid="hirer-header-search"
    >
      <HeaderSelect
        value={null}
        highlight={query}
        placeholder="Quick lookup"
        onChange={e => handleSelect(e as QuickSearchResult)}
        options={handleHeaderSelectOption(data?.results || [], lang as string, selectInputValue, isDesktop)}
        onInputChange={(value) => {
          handleInputChange(value);
          setSelectInputValue(value);
        }}
        onMenuOpen={handleSelectMenuOpen}
        onMenuClose={() => setIsShowSelectMenu(false)}
        menuIsOpen={isShowSelectMenu}
        { ...props }
      />
    </div>
  );
};
export default HirerHeaderSearch;
