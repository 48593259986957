"use client";

import React, { useCallback, useState } from "react";
import { useFormik, FormikHelpers } from "formik";
import debounce from "lodash/debounce";
import classNames from "classnames";
import get from "lodash/get";
import dynamic from "next/dynamic";

// components
import Link from "@/components/Link";
import LoginFormFooter from "../LoginFormFooter";
import Input from "@/shared/Input";
import Checkbox from "@/shared/Checkbox";
import Button from "@/shared/Button";
import AuthFormWrapper from "../AuthFormWrapper";
const VerifyEmailWarningModal = dynamic(() => import("@/components/Modals/VerifyEmailWarningModal"), { ssr: false });

// helpers
import { validationSchema, initialValues, errorParser } from "./helpers";
import wording from "@/globals/wording.constants";
import account from "@/api/account";
import useUserProfile from "@/hooks/useUserProfile";
import useRouter from "@/hooks/useRouter";

// interfaces
import { LoginFormInterface, LoginFormValuesInterface } from "./LoginForm.interface";

// assets
import styles from "@/assets/styles/components/Auth/auth-login-form.module.scss";


const LoginForm = (props: LoginFormInterface) => {
  const { isRowView, isWithFooter, title = wording.auth.login.title, message, className, onSubmit } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

  const router = useRouter();
  const { refetch, isLoading: isUserDateLoading } = useUserProfile();

  const formGroupClassNames = classNames(
    [styles["locl-auth-login-form__group"]],
    className,
    { [styles["locl-auth-login-form__group-row"]]: isRowView }
  );

  const handleSubmitCallback = (userType: string) => {
    if (userType === "hirer") {
      onSubmit && onSubmit(userType);

      return;
    }

    router.push("/locations");
  };

  const handleSubmit = async (data: LoginFormValuesInterface, formMethods: FormikHelpers<LoginFormValuesInterface>) => {
    setIsLoading(true);

    const { email, password, rememberMe } = data;

    await account.postToken({ email, password } as { email: string; password: string }, rememberMe as boolean)
      .then(async () => {
        const userData = await refetch();
        
        handleSubmitCallback(userData?.data?.type || "")
      })
      .catch(err => {
        if (get(err, "response.data.resendVerification")) {
          setIsVerifyModalOpen(true);

          return;
        }

        if (get(err, "response.data")) {
          errorParser(err.response.data, formMethods);
        }

        return err;
      })
      .finally(() => setIsLoading(false));
  };

  const methods = useFormik<LoginFormValuesInterface>({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSubmit,
  });

  const {
    values,
    errors,
    setFieldValue,
    validateField,
    handleSubmit: formikHandleSubmit,
  } = methods;

  const validateFieldValue = useCallback(debounce(key => validateField(key), 300), []);

  const handleFieldChange = async (key: string, value: unknown) => {
    await setFieldValue(key, value);
    validateFieldValue(key);
  };

  return (
    <AuthFormWrapper
      title={title}
      subtitle={!message && wording.auth.login.subtitle}
      message={message}
    >
      <form
        className={styles["locl-auth-login-form"]}
        onSubmit={formikHandleSubmit}
        data-testid="locl-login-form"
      >
        <div className={styles["locl-auth-login-form__fields"]}>
          <div className={formGroupClassNames}>
            <Input
              name="email"
              error={errors.email}
              value={values.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              label="Business email address"
              placeholder="example@gmail.com"
              data-testid="locl-login-input-email"
            />
            <Input.Password
              label="Password"
              error={errors.password}
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              data-testid="locl-login-input-password"
            />
          </div>
          <div className={`${styles["locl-auth-login-form__group"]} ${styles["locl-auth-login-form__group-row"]}`}>
            <Checkbox
              checked={!!values.rememberMe}
              onChange={(e) => handleFieldChange("rememberMe", e.target.checked)}
              size="small"
              data-testid="locl-login-checkbox-remember-me"
            >
              Remember me
            </Checkbox>
            <Link
              href="/forgot-password"
              className={styles["locl-auth-login-form__forgot-password"]}
              data-testid="locl-login-link-forgot-password"
            >
              Forgot password?
            </Link>
          </div>
        </div>
        <Button
          fullWidth
          htmlType="submit"
          loading={isLoading || isUserDateLoading}
          data-testid="locl-login-button-submit"
        >
          Log in
        </Button>
        <div className={styles["locl-auth-login-form__action"]}>
            Not a member?{" "}
          <Link
            className={styles["locl-auth-login-form__action-link"]}
            href="/registration"
            data-testid="locl-login-link-sign-up"
          >
            Sign up
          </Link>
        </div>
        {isWithFooter && <LoginFormFooter />}
      </form>
      <VerifyEmailWarningModal
        open={isVerifyModalOpen}
        onCancel={() => setIsVerifyModalOpen(false)}
        email={values.email as string}
      />
    </AuthFormWrapper>
  );
};

export default LoginForm;
