import React from "react";

//interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";

function MyMessagesIcon(props: IconComponentProps<HTMLSpanElement>) {

  return (
    <span { ...props }>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" stroke="currentColor" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M7.07783 22.308C9.78583 22.308 11.9818 20.112 11.9818 17.404C11.9818 14.696 9.78683 12.5 7.07783 12.5C4.36883 12.5 2.17383 14.696 2.17383 17.404" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.083 22.3152C6.345 22.3152 5.646 22.1522 5.018 21.8612L2 22.5023L2.631 19.4783C2.338 18.8483 2.174 18.1463 2.174 17.4062" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.557 12.5278C6.199 11.7068 6 10.8118 6 9.87281C6 5.85581 9.598 2.63281 14 2.63281C18.402 2.63281 22 5.85581 22 9.87281C22 12.2468 20.738 14.3368 18.801 15.6548C18.802 16.4108 18.8 17.4298 18.8 18.4998L15.661 16.9538C15.124 17.0568 14.569 17.1128 14 17.1128C13.293 17.1128 12.607 17.0288 11.953 16.8718" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
}

export default MyMessagesIcon;
